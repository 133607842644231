.header {
    position: sticky;
    height: 70px;
    width: 100vw;
    background-color: #002147;

    display: flex;
    align-items: center;
}

.header h1 {
    font-weight: normal;
    font-size: 50px;
    color: white;
    margin: none;
}

.header .logo {
    margin-left: auto;
    padding: 0 50px;
    text-align: right;
}

.header .subtitle {
    color: white;
}