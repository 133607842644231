.portfolio-builder {
    position: relative;
}

.portfolio-builder-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    height: 100%;
}

.portfolio-builder .title {
    font-size: large;
}

.portfolio-builder .current-stocks {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}

.portfolio-builder .stocks {
    margin-top: 20px;
}

.portfolio-builder .search-result {
    cursor: pointer;
    padding: 5px;
    background-color: rgb(251, 251, 251);
    border: 1px solid #DDD;
    box-shadow: 1px 2px 5px 1px rgba(0,0,0,.2);
}

.portfolio-builder .search-result:last-of-type {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}