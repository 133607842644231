.home-container {
    width: 100%;
}

.header {
    background-color: #002147;
    padding: 20px;
    margin: none;
    overflow: hidden;
    width: 100%;
}

.form {
    width: 100%;
    position: relative;
    max-width: 800px;
    margin: 0 auto !important;
}

.switch {
    padding: 20px;
}

.switch .option {
    cursor: pointer;
    background-color: #888;
    text-align: center;
    border-radius: 5px;
    padding: 20px;
    color: 'black'
}

.switch .selected {
    background-color: #002147;
    color: 'white';
}

.submit-button {
    padding: 20px;
    width: 100%;
    background-color: #002147;
}

.button-wrapper {
    padding: 10px;
    margin: 20px 0;
}